.App {
  text-align: center;

}


@media (prefers-reduced-motion: no-preference) {}

.page-section {
  padding: 6rem 0;
}

.text-color {
  color: #AAAA !important;
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  justify-content: space-around;
}


divider-custom {
  margin: 1.25rem 0 1.5rem;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.divider-custom .divider-custom-line {
  width: 100%;
  max-width: 7rem;
  height: 0.25rem;
  background-color: #2c3e50;
  border-radius: 1rem;
  border-color: #2c3e50 !important;
}

.divider-custom .divider-custom-line:first-child {
  margin-right: 1rem;
}

.divider-custom .divider-custom-line:last-child {
  margin-left: 1rem;
}

.divider-custom .divider-custom-icon {
  color: #2c3e50 !important;
  font-size: 2rem;
}

.divider-custom.divider-light .divider-custom-line {
  background-color: #fff;
}

.divider-custom.divider-light .divider-custom-icon {
  color: #fff !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.fundo {
  background-color: #1abc9c;
  padding-top: calc(6rem + 74px);
  padding-bottom: 6rem;
}

.bg-primary {
  background-color: #1abc9c !important;
}

.bg-primary2 {
  background-color: #1e6859 !important;
}

.masthead {
  padding-top: calc(6rem + 10px);
  padding-bottom: 6rem;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

}

.card-servicos {
  /* padding-top: 6rem; */
  /* padding-bottom: 6rem; */
  margin: 10px 10px 10px 10px;
  /* margin-left: 6rem; */
  /* margin-right: 6rem; */
}

.card-img {
  padding: 10px 10px 10px 10px;
  margin-top: 10px;
  width: 100%;
  margin-left: auto;
  margin-right: auto
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}